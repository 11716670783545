import { Snackbar, Alert } from '@mui/material';
import styles from './styles.module.scss';

interface MessageNotice {
  message: string;
  show: boolean;
  onChange: (value: boolean) => void;
  status?: 'success' | 'error' | 'warning' | 'info';
}

export const MessageNotice = ({ message, show, onChange, status }: MessageNotice) => {
  const { notice, success, alert, error } = styles;

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    onChange(false);
  };

  return (
    <Snackbar
      open={show}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      className={`${notice} ${status === 'error' ? error : success}`}
    >
      <Alert onClose={handleClose} severity={status ? status : 'info'} className={alert}>
        {message}
      </Alert>
    </Snackbar>
  );
};
