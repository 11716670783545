import { FileIcon } from 'assets/icons';
import { Title, ImageWrapper, Link, Button, LinkWrapper } from './styles';

interface FileDownloadItem {
  id: number;
  name: string;
  url: string;
}

export const FileDownloadItem = ({ id, name, url }: FileDownloadItem) => {
  return (
    <LinkWrapper>
      <Link>
        <ImageWrapper>
          <FileIcon />
        </ImageWrapper>

        <Title>{name}</Title>
      </Link>
      <Button href={url} download>
        Скачать
      </Button>
    </LinkWrapper>
  );
};

export default FileDownloadItem;
