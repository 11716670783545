import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useChangeStatusMutation } from 'hooks/useApiRequest';
import { statuses, getActiveStatus } from 'utils';
import { DetailInfoProps } from 'utils/ts/interfaces';
import { ActiveStatuses } from 'utils/ts/customTypes';
import { setDetails } from 'store/slices/applicationDetails';
import { setActiveStatus } from 'store/slices/activeStatus';
import { SelectStatus } from 'components/SelectStatus';
import { ApplicationStatus } from 'components/ApplicationStatus';
import { DateFormat } from 'components/Date';
import { MessageNotice } from 'components/MessageNotice';
import { useUserCheck } from 'hooks';
import { ArrowLeft } from 'assets/icons';
import { controlPanelStyles } from './styles';
import styles from './styles.module.scss';

const { Header, HeaderLeft, HeaderTitle } = controlPanelStyles;

interface ApplicationControlPanelProps extends DetailInfoProps {
  isHavingsUser: boolean;
  isEdit: boolean;
}

export const ApplicationControlPanel = (props: ApplicationControlPanelProps) => {
  const { updatedAt, id, manager, isHavingsUser, status, isEdit } = props;
  const [currentActiveStatus, setCurrentActiveStatus] = useState<ActiveStatuses | null>(
    null,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [changeStatus] = useChangeStatusMutation();
  const endpoint = `${id}/${status === 'Новая' && manager === null ? 'accept/' : ''}`;
  const { arrowBack, priority, secondary, responsible } = styles;
  const user = useUserCheck();
  const [showNotice, setShowNotice] = useState(false);
  const [notice, setNotice] = useState('');

  useEffect(() => {
    const newActiveStatus = getActiveStatus(status);
    const difference = newActiveStatus === currentActiveStatus;
    setCurrentActiveStatus(difference ? null : newActiveStatus);

    if (!difference) {
      dispatch(setActiveStatus(newActiveStatus));
    }
  }, [status]);

  const goBack = () => {
    navigate(-1);
  };

  const sendChanges = (name: any) => {
    const index = statuses.findIndex(el => el === name);

    const params = {
      status: index === 0 ? statuses[index + 1] : statuses[index],
    };

    changeStatus({ params, endpoint })
      .unwrap()
      .then((res: any) => {
        console.log(res);
        let newDetails = { ...props, ...res };

        if (res.status === 'В работе') {
          newDetails.userId = user?.id;
        }
        setNotice('');
        dispatch(setDetails(newDetails));
      })
      .catch((error: any) => {
        console.log('here', error);
        setShowNotice(true);
        setNotice(
          (error.data.error.details &&
            error.data.error.details.length &&
            error.data.error.details[0].message) ||
            'Ошибка! При выполнении данного действия что-то пошло не так!',
        );
      });
  };

  return (
    <Header>
      <HeaderLeft>
        <button onClick={goBack} className={arrowBack}>
          <ArrowLeft />
        </button>
        <HeaderTitle>
          <div className={priority}>Заявка №{id}</div>
          <div className={secondary}>
            от <DateFormat date={updatedAt ? updatedAt : ''} />
          </div>
        </HeaderTitle>
        <ApplicationStatus
          status={status}
          theme={status === statuses[7] ? 'green' : status === statuses[8] ? 'red' : ''}
        />
      </HeaderLeft>
      {isHavingsUser || isEdit ? (
        <>
          {status !== 'Выдано' ? (
            <>
              {status === 'Новая' ? (
                <ApplicationStatus
                  status={status}
                  onChange={sendChanges}
                  control={true}
                  text={'Взять в работу'}
                />
              ) : (
                <SelectStatus
                  options={statuses.filter(value => value !== statuses[0])}
                  active={status}
                  onChange={sendChanges}
                />
              )}
            </>
          ) : null}
        </>
      ) : (
        <div className={responsible}>
          <div>{manager?.fullName}</div>
          <div className={secondary}>Ответственный менеджер</div>
        </div>
      )}
      <MessageNotice
        message={notice}
        show={showNotice}
        status="error"
        onChange={setShowNotice}
      />
    </Header>
  );
};
