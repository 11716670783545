import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useChangeStatusMutation } from 'hooks/useApiRequest';
import { getActiveStatus } from 'utils';
import { DetailInfoProps } from 'utils/ts/interfaces';
import { ActiveStatuses } from 'utils/ts/customTypes';
import { setDetails } from 'store/slices/applicationDetails';
import { setActiveStatus } from 'store/slices/activeStatus';
import { SelectStatus } from 'components/SelectStatus';
import { ApplicationStatus } from 'components/ApplicationStatus';
import { DateFormat } from 'components/Date';
import { MessageNotice } from 'components/MessageNotice';
import { useUserCheck, useFiltersCheck } from 'hooks';
import { useGetStatusesMutation } from 'hooks/useApiRequest';
import { setStatuses } from 'store/slices/filters';
import { ArrowLeft } from 'assets/icons';
import { controlPanelStyles } from './styles';
import styles from './styles.module.scss';

const { Header, HeaderLeft, HeaderTitle } = controlPanelStyles;

interface ApplicationControlPanelProps extends DetailInfoProps {
  isHavingsUser: boolean;
  isEdit: boolean;
  disabled?: boolean;
}

export const ApplicationControlPanel = (props: ApplicationControlPanelProps) => {
  const { updatedAt, id, manager, isHavingsUser, status, isEdit, disabled } = props;
  const [currentActiveStatus, setCurrentActiveStatus] = useState<ActiveStatuses | null>(
    null,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [changeStatus] = useChangeStatusMutation();
  const endpoint = `${id}/${status === 'Новая' && manager === null ? 'accept/' : ''}`;
  const { arrowBack, priority, secondary, responsible } = styles;
  const user = useUserCheck();
  const [showNotice, setShowNotice] = useState(false);
  const [notice, setNotice] = useState('');
  const { statuses } = useFiltersCheck();
  const [getStatuses] = useGetStatusesMutation();

  useEffect(() => {
    if (!statuses.length) {
      getStatuses()
        .unwrap()
        .then((data: any) => {
          dispatch(setStatuses(data));
        });
    }
  }, []);

  useEffect(() => {
    const newActiveStatus = getActiveStatus(status);
    const difference = newActiveStatus === currentActiveStatus;
    setCurrentActiveStatus(difference ? null : newActiveStatus);

    if (!difference) {
      dispatch(setActiveStatus(newActiveStatus));
    }
  }, [status]);

  const goBack = () => {
    navigate(-1);
  };

  const sendChanges = (name: any) => {
    const index = statuses.findIndex(el => el.value === name);

    const params = {
      status: index === 0 ? statuses[index + 1].value : statuses[index].value,
    };

    changeStatus({ params, endpoint })
      .unwrap()
      .then((res: any) => {
        let newDetails = { ...props, ...res, userId: user?.id };

        setNotice('');
        dispatch(setDetails(newDetails));
      })
      .catch((error: any) => {
        console.log('here', error);
        setShowNotice(true);
        setNotice(
          (error?.data?.error?.details &&
            error.data.error.details.length &&
            error.data.error.details[0].message) ||
            'Ошибка! При выполнении данного действия что-то пошло не так!',
        );
      });
  };

  return (
    <Header>
      <HeaderLeft>
        <button onClick={goBack} className={arrowBack}>
          <ArrowLeft />
        </button>
        <HeaderTitle>
          <div className={priority}>Заявка №{id}</div>
          <div className={secondary}>
            от <DateFormat date={updatedAt ? updatedAt : ''} />
          </div>
        </HeaderTitle>
        <ApplicationStatus
          status={status}
          theme={status === 'Выдано' ? 'green' : status === 'Отказ клиента' ? 'red' : ''}
        />
      </HeaderLeft>
      {isHavingsUser || isEdit ? (
        <>
          {status !== 'Выдано' ? (
            <>
              {status === 'Новая' ? (
                <ApplicationStatus
                  status={status}
                  onChange={sendChanges}
                  control={true}
                  text={'Взять в работу'}
                />
              ) : (
                <SelectStatus
                  options={statuses.filter(value => value.value !== 'Новая')}
                  active={status}
                  onChange={sendChanges}
                  disabled={disabled}
                />
              )}
            </>
          ) : null}
        </>
      ) : (
        <div className={responsible}>
          <div>{manager?.fullName}</div>
          <div className={secondary}>Ответственный менеджер</div>
        </div>
      )}
      <MessageNotice
        message={notice}
        show={showNotice}
        status="error"
        onChange={setShowNotice}
      />
    </Header>
  );
};
