import styled from 'styled-components';

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Link = styled.div`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 286px;
`;

export const Button = styled.a`
  text-decoration: none;
  font-family: 'Open Sans';
  font-size: 12px;
  line-height: 13.92px;
  padding: 6px 10px 7px 10px;
  color: #333333;
  border-radius: 20px;
  background: #eceff2;
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 19.6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333333;
`;

export const ImageWrapper = styled.div`
  position: relative;

  > svg {
    width: 24px;
    height: 24px;
    fill: #49a3a6;
  }
`;
